<script setup lang="ts">
    import { defineProps, withDefaults } from 'vue';
    import { GroupProgress } from 'o365.pwa.modules.client.steps.GroupProgress.ts';
    import GroupProgressOnline from "o365.pwa.vue.components.steps.GroupProgressOnline.vue";
    import GroupProgressOffline from "o365.pwa.vue.components.steps.GroupProgressOffline.vue";

    interface IProps {
        syncStepProgress: GroupProgress,
        currentStep: boolean
    }

    const props = withDefaults(defineProps<IProps>() ,{
        currentStep: false
    });
</script>

<script lang="ts">
    export default {
        name: 'GroupProgress'
    }
</script>

<template>
    <GroupProgressOnline v-if="props.syncStepProgress.syncType === 'ONLINE-SYNC'" :syncStepProgress="props.syncStepProgress" :currentStep="props.currentStep" />
    <GroupProgressOffline v-else-if="props.syncStepProgress.syncType === 'OFFLINE-SYNC'" :syncStepProgress="props.syncStepProgress" :currentStep="props.currentStep" />
</template>
